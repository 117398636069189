
import { motion} from "framer-motion"
import { cubicBezier } from "framer-motion";

export default function Transition({ children }) {


    const easing = cubicBezier(.68,0,.26,1)
    const animIn = {
        in: { y: ["0","150vh"] },
        current: { y: "150vh" },
        out: { y: ["-150vh","0"] }
    }

  return (
    <>
      <motion.div
        variants={animIn}
        initial="in"
        animate="current"
        exit="out"
        transition={{duration:"0.5", ease: easing}}
        

        className="bg-faded z-[99] scrollbar-hide drop-shadow-sm"
        style={{ height: "150vh", width: "100vw", position: "fixed"}}
      />
      <motion.div 
        initial={{ y: "-15%", scaleY:"115%" }} 
        animate={{ y: "0", scaleY:"100%" }} 
        exit={{ y: "15%", scaleY:"115%" }} 
        transition={{duration:"0.8", ease: cubicBezier(0.3,0,0,1)}}
      >
        {children}
      </motion.div>
    </>
  );
}


