

import React, { useState } from "react"
import { Link } from "react-router-dom"
// import Nav from "../components/Nav";

function Gallery () {
    
    const img = [
        "gallery1.gif",
        "gallery2.gif",
        "gallery3.gif",
        "gallery4.gif",
        "gallery5.gif",
        "gallery6.gif",
        "gallery7.gif",
    ]
    return(
        <div className="w-full h-full overflow-x-hidden">
            <div className="w-fit h-full flex ">
                <div className="w-fit h-full flex justify-between scroller1">
                    {img.map((y)=>
                        (
                            <div key={y} className="aspect-[9/16] mr-12 h-full bg-primary border-faded border-[1px]">
                                <img className="h-full w-full object-cover" src={`../gif/${y}`} alt="landing-gif-gallery"/>
                            </div>
                        )
                    )}
                </div>
                <div className="w-fit h-full flex justify-between scroller1">
                    {img.map((y)=>
                        (
                            <div key={y} className="aspect-[9/16] mr-12 h-full bg-primary border-faded border-[1px]">
                                <img className="h-full w-full object-cover" src={`../gif/${y}`} alt="landing-gif-gallery"/>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
        
    )
}

function Landing() {
  const [workHover, setworkHover] = useState(false);

  return (
    <div className="overflow-hidden w-screen h-screen flex justify-center items-center">
        <section className="max-w-[1440px] aspect-square lg:aspect-video px-8 py-28 lg:p-32 h-full w-full flex flex-col justify-between items-center">
            <Link to="/works" onMouseEnter={()=>{setworkHover(true)}} onMouseLeave={()=>{setworkHover(false)}} className="w-full h-full border-x-[1px] border-faded transition-all" style={{
                        scale: (workHover ? '1.01' : '1'),
                        opacity: (workHover ? '0.8' : '1'),
                    }}>
                <Gallery />
            </Link>
            <div className="flex w-full justify-between items-center mt-8">
                <div className="flex justify-start items-end">
                    <h1 className="font-header text-4xl lg:text-8xl  text-primary ">
                        Colin Tan
                    </h1>
                    <span className="ml-2 lg:ml-8 font-sans font-light antialiased text-xs lg:text-lg">
                        Digital Portfolio
                    </span>
                </div>
                <Link onMouseEnter={()=>{setworkHover(true)}} onMouseLeave={()=>{setworkHover(false)}} to="/works" className="group flex items-center space-x-3">
                    <span className="font-light hidden lg:block group-hover:tracking-wider group-hover:opacity-30 transition-all" style={{
                        letterSpacing: (workHover ? '0.05em' : '0.01em'),
                        opacity: (workHover ? '0.3' : '1'),
                    }}>
                        View works  
                    </span>
                    <div className="p-2 rounded-full border-primary border-[1px]  transition-all"
                    style={{
                        backgroundColor: (workHover ? '#1e1e1e' : 'transparent'),
                        color: (workHover ? '#f1f1ee' : '#1e1e1e'),
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                        </svg>
                    </div>
                </Link>
            </div>
        </section>
    </div>
  )
}

export default Landing

