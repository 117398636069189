


import React, { useState } from "react"

import { Link,useLocation } from 'react-router-dom';


const Nav = () => {
    const [navopened, setnavopen] = useState(false);

  const location = useLocation();


  return (  
    <nav className="fixed top-0 left-0 flex z-[9999]">
        <button className="min-w-24 h-24 border-[1px] flex items-center justify-center bg-secondary border-faded cursor-pointer hover:bg-select transition-all" onClick={()=>{setnavopen((c) => !c)}}>
          {
            navopened ? (
              <svg width="54" height="44" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="2.64319" y1="1.2343" x2="52.6432" y2="43.2343" stroke="#1E1E1E" strokeWidth="2"/>
              <line x1="1.35681" y1="43.2343" x2="51.3568" y2="1.23429" stroke="#1E1E1E" strokeWidth="2"/>
              </svg>
            ) : (
              <svg width="50" height="44" viewBox="0 0 50 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="1" y1="1" x2="50" y2="1" stroke="#1E1E1E" strokeWidth="2"/>
              <line x1="1" y1="22" x2="33" y2="22" stroke="#1E1E1E" strokeWidth="2"/>
              <line x1="1" y1="43" x2="20" y2="43" stroke="#1E1E1E" strokeWidth="2"/>
              </svg>
            )
          }
        </button>
        <div className="absolute left-24 flex flex-col w-72 border-[1px] -translate-x-[1px] -translate-y-[2px] bg-secondary border-faded divide-y-[1px] divide-faded font-header text-5xl transition-opacity duration-500" style={{opacity:(navopened ? '1' : '0'), pointerEvents:(navopened ? 'auto' : 'none')}}>
            <Link to='/' onClick={()=>{setnavopen(false)}} className={`
                h-24 w-full flex items-center text-start py-3 px-8 bg-transparent hover:bg-select transition-all
                ${(location.pathname === '/' ? '!bg-select' : '')}`}>
                Top
            </Link>
            <Link to='/works' onClick={()=>{setnavopen(false)}} className={`
                h-24 w-full flex items-center text-start py-3 px-8 bg-transparent hover:bg-select transition-all
                ${(location.pathname === '/works' ? '!bg-select' : '')}`}>
                Works
            </Link>
            <Link to='/info' onClick={()=>{setnavopen(false)}} className={`
                h-24 w-full flex items-center text-start py-3 px-8 bg-transparent hover:bg-select transition-all
                ${(location.pathname === '/info' ? '!bg-select' : '')}`}>
                Info
            </Link>
        </div>
    </nav>
  )
}

export default Nav
