import React, {useState, useEffect} from "react"
// import info from './data/descriptions.json'
import Landing from "./sections/landing"
import Info from "./sections/info"
import Works from "./sections/projects"
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import Transition from "./components/Transition";
import Nav from "./components/Nav";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Transition><Landing /></Transition>} />
        <Route path="/info" element={<Transition><Info /></Transition>} />
        <Route path="/works" element={<Transition><Works /></Transition>} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [isRevealed, setIsRevealed] = useState(false);

  useEffect(() => {
    // Delay the reveal for 1 second (optional)
    const timer = setTimeout(() => {
      setIsRevealed(true);
    }, 1000);

    // Cleanup timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="overflow-hidden scrollbar-hide bg-faded">
      <BrowserRouter>
        <div className="fixed top-0 left-0 z-[9999] transition-all duration-1000" style={{opacity:(isRevealed ? '1' : '0' )}}>
          <Nav /> 
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={isRevealed}
            initial={{ opacity:1 }} 
            animate={{ opacity:1 }} 
            exit={{ opacity:0 }} 
            transition={{duration:"0.5"}}
          >
            {
              isRevealed ? 
              (
                <div className="bg-secondary">
                  <AnimatedRoutes />
                </div>
              ) : (
                <div className="w-screen h-screen flex flex-col items-center justify-center p-12 font-light">
                  <span className="text-xs  mr-4">
                    Reproduction of this site and its content is prohibited.
                  </span>
                  <div className="w-32 my-4 border-primary border-[1px] overflow-hidden">
                    <hr className="border-primary animate-scroll" />
                  </div>
                </div>
              )
            }
          </motion.div>
        </AnimatePresence>
      </BrowserRouter>
    </div>
  )
}

export default App

