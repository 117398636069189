

import React, { useState,useEffect,useRef } from "react"
import ProjectJson from '../data/projects.json'
import { Link } from "react-router-dom";
// import Nav from "../components/Nav";
// import Lenis from "@studio-freight/lenis";


function Thumbnail({link,title,thumbnail}) {

  return(
    <a href={link} key={title} className="w-full lg:w-96 lg:min-w-96 relative group lg:rotate-3 hover:rotate-0 transition-all">
      <img
        className="w-full relative "
        src={require(`../data/img/${thumbnail}`)}
        alt={title}
      />
      <div className="absolute top-0 w-full h-full text-white flex justify-center items-center backdrop-blur-sm bg-gradient-to-t from-black/80 to-transparent  group-hover:opacity-100 opacity-0  transition-all duration-300">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"  viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
          <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
        </svg>
      </div>
      <span className="pointer-events-none lg:block hidden absolute text-2xl font-thin left-3 bottom-0 translate-y-[calc(100%+1rem)] group-hover:-translate-y-4 group-hover:text-secondary transition-all">
        {title}
      </span>
    </a>
  )
}

function Works() {
  const pageRef = useRef(null)
  const containerRef = useRef(null);
  const [scrollamount, setScroll] = useState(0)
  
  // useEffect(() => {
  //   function enableScrollBehavior() {
  //     if (window.innerWidth < 1024) return;
  
  //     const scrollContainer = pageRef.current;
  //     if (!scrollContainer) return;
  
  //     // Wheel scroll handler
  //     function handleWheel(evt) {
  //       evt.preventDefault();

  
  //       scrollContainer.scrollLeft += evt.deltaY + evt.deltaX;

  //     }
  
  //     scrollContainer.addEventListener("wheel", handleWheel);
  
  //     return () => {
  //       scrollContainer.removeEventListener("wheel", handleWheel);
  //     };
  //   }
  
  //   enableScrollBehavior();
  
  //   const handleResize = () => {
  //     if (window.innerWidth >= 1024) {
  //       enableScrollBehavior();
  //     }
  //   };
  
  //   window.addEventListener("resize", handleResize);
  
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  function enableScrollBehavior() {
    if (window.innerWidth < 1024) return;

    const scrollContainer = pageRef.current;
    if (!scrollContainer) return;

    let timeout;
    let isScrolling = false;
    let currentScrollLeft = 0;
    let targetScrollLeft = 0;
    
    
    // Smooth scroll logic
    function smoothScroll() {
      if (!isScrolling) return;
      // currentScrollLeft = scrollContainer.scrollLeft;

      currentScrollLeft += (targetScrollLeft - currentScrollLeft) * 0.1;

      if (Math.abs(targetScrollLeft - currentScrollLeft) < 0.5) {
        currentScrollLeft = targetScrollLeft;
        isScrolling = false;
      }

      scrollContainer.scrollLeft = currentScrollLeft;

      if (isScrolling) {
        requestAnimationFrame(smoothScroll);
      }

    }

    // Wheel scroll handler
    function handleWheel(evt) {
      // currentScrollLeft = targetScrollLeft
      evt.preventDefault();

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        isScrolling = false;
      }, 200);

      targetScrollLeft += evt.deltaY + evt.deltaX;
      targetScrollLeft = Math.max(
        0,
        Math.min(
          scrollContainer.scrollWidth - scrollContainer.clientWidth,
          targetScrollLeft
        )
      );

      if (!isScrolling) {
        isScrolling = true;
        smoothScroll();
      }
    }

    scrollContainer.addEventListener("wheel", handleWheel);

    return () => {
      scrollContainer.removeEventListener("wheel", handleWheel);
    };
  }
  useEffect(() => {
  
    // enableScrollBehavior();
  
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        enableScrollBehavior();
      }
    };
  
    window.addEventListener("resize", handleResize);
    const cleanup = enableScrollBehavior();
    
    return () => {
      window.removeEventListener("resize", handleResize);
      cleanup && cleanup()
    };
  }, [pageRef]);

  useEffect(() => {
    pageRef.current.addEventListener('scroll', () => {
      // console.log(`Horizontal scroll: ${pageRef.current.scrollLeft / pageRef.current.innerWidth}px`);
      setScroll((pageRef.current.scrollLeft / (pageRef.current.scrollWidth - pageRef.current.clientWidth))*100)
    });
    return () => {
      
    };
  }, []);

  return (
    <div ref={pageRef} className="overflow-visible scrollbar-hide lg:overflow-hidden w-full h-full pageref">
        {/* <Nav /> */}
      <div className={`lg:flex w-fit lg:h-screen h-full items-center px-8 lg:px-24 bg-secondary`} ref={containerRef}>
        <div className="w-full lg:w-[600px] h-96 flex flex-col justify-center mr-32 relative">
          <h1 className="text-6xl font-header">
            My works
          </h1>
          <p className="font-light my-8">
            A collection of work over the past couple of years.
          </p>
          <div className="flex absolute bottom-0 right-0 origin-right lg:rotate-0 rotate-90 items-center font-light justify-end">
            <span className="mr-4">
              Scroll  
            </span>
            <div className="w-12 lg:w-24 overflow-x-hidden bg-primary/20">
              <hr className="border-primary animate-scroll" />
            </div>
          </div>
        </div>
        <div className="lg:flex items-center w-fit space-x-24 py-8 lg:py-0">
          {ProjectJson.map((obj)=>(
            <Thumbnail key={obj.title} link={obj.link} title={obj.title} thumbnail={obj.thumbnail} />
          ))}
        </div>
        <Link
          to="/info"
          className="w-32 h-96 ml-32 hidden lg:flex items-center justify-center bg-secondary group hover:bg-select border-faded border-[1px] transition-all duration-700">
          <svg className="size-20 rotate-180 group-hover:scale-90 transition-all duration-700 text-primary" fill="currentColor" viewBox="0 0 16 16">
            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
          </svg>
        </Link>
      </div>
      <div className="fixed bottom-12 right-24 ">
        <div className="w-48 h-1 rounded-full border-primary border-[1px] transition-all duration-700" style={{opacity:`${scrollamount>0.1 ? "1":"0"}`}}>
          <div className="h-full bg-primary" style={{width:`${scrollamount}%`}} />
        </div>
      </div>
    </div>
  )
}

export default Works

